import { GamesCard, GamesCardType } from '../gamesCard';
import { CasinoCardDescriptionType } from './casinoCardDescription';

export type CasinoCardProps = GamesCardType & CasinoCardDescriptionType;

export const CasinoCard = (props: CasinoCardProps) => {
  return (
    <GamesCard {...props}>{/* {props.category && <CasinoCardDescription category={props.category} />} */}</GamesCard>
  );
};
