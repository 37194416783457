import styles from './CardRouletteList.module.scss';

export type CardRouletteListProps = {
  listItems: NumberProps[];
};

export type NumberProps = {
  id?: string;
  value: number;
  bgColor: 'red' | 'black';
};

type numberBgColorDictionary = {
  [key: string]: string;
};

const numberBgColorDictionary: numberBgColorDictionary = {
  red: '--color-cat-21',
  black: '--color-neutral-02',
};

export const CardRouletteList = ({ listItems }: CardRouletteListProps) => {
  return (
    <p className={styles.container}>
      {listItems &&
        listItems.map((item) => {
          const { id, value, bgColor } = item;
          return <Number key={id} value={value} bgColor={bgColor} />;
        })}
    </p>
  );
};

const Number = ({ value, bgColor }: NumberProps) => {
  return (
    <span className={styles.number} style={{ backgroundColor: `var(${numberBgColorDictionary[bgColor]})` }}>
      {value}
    </span>
  );
};
