import styles from './CardsGamesCardDescription.module.scss';

export type CardsGamesCardDescriptionProps = {
  gameMode: string[];
};

export const CardsGamesCardDescription = ({ gameMode }: CardsGamesCardDescriptionProps) => {
  return (
    <p className={styles.container}>
      {gameMode.map((singleGameMode, _, self) => {
        return singleGameMode + (self.length > 1 ? ', ' : ' ');
      })}
    </p>
  );
};
