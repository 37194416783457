import React from 'react';
import { convertInCurrencyValue } from '../../../utility/functions';
import styles from './ChartCard.module.scss';

export type ChartCardProps = {
  name: string;
  time?: string;
  amount: number;
  currency: string;
  position: number;
};

export const ChartCard: React.FC<ChartCardProps> = ({ position, name, time, amount, currency }: ChartCardProps) => {
  return (
    <article className={styles.container}>
      <span className={styles.position}>{position}</span>
      <div className={styles.card}>
        <p className={styles.name}>{name}</p>
        <div className={`${styles.textContainer} ${time ? styles.fullContainer : ''}`}>
          {time && <span className={styles.time}>{time.substring(11, 16)}</span>}
          <p className={styles.fullAmount}>{convertInCurrencyValue(amount / 100, currency)}</p>
        </div>
      </div>
    </article>
  );
};
