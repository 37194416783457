import { useGetLabelByKey } from 'hooks/useLingUI';
import styles from './CasinoLiveCardDescription.module.scss';

export type CasinoLiveCardDescriptionProps = {
  language: string;
  seatsAvailable?: number;
};

export const CasinoLiveCardDescription = ({ language, seatsAvailable }: CasinoLiveCardDescriptionProps) => {
  const seatsAvailableLabel = useGetLabelByKey('sets-available');
  return (
    <p className={styles.container}>
      {language.toUpperCase()}&nbsp;
      {seatsAvailable ? `| ${seatsAvailableLabel} ${seatsAvailable}` : ''}
    </p>
  );
};
