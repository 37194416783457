import { CardAppIcon } from 'components/cardAppIcon';
import { CardsGamesCard } from 'components/cards/cardsGamesCard';
import { CasinoCard } from 'components/cards/casinoCard';
import { CasinoLiveCard } from 'components/cards/casinoLiveCard';
import { ChartCard } from 'components/cards/chartCard';
import { PokerCard } from 'components/cards/pokerCard';
import { SlotCard, SlotCardType } from 'components/cards/slotCard';
import { CardTypes } from 'components/cards/types';
import { WinnerCard } from 'components/cards/winnerCard';
import { WithNumberCard } from 'components/cards/withNumberCard';
import { FavTeamCard } from 'components/favTeamCard';
import { LazyGetTriggerType } from 'features/slotArchive/actions';
import React from 'react';

export enum enCardType {
  // eslint-disable-next-line no-unused-vars
  app = 'app',
  // eslint-disable-next-line no-unused-vars
  slot = 'slot',
  // eslint-disable-next-line no-unused-vars
  chart = 'chart',
  // eslint-disable-next-line no-unused-vars
  top10 = 'top10',
  // eslint-disable-next-line no-unused-vars
  poker = 'poker',
  // eslint-disable-next-line no-unused-vars
  casino = 'casino',
  // eslint-disable-next-line no-unused-vars
  casinolive = 'casinolive',
  // eslint-disable-next-line no-unused-vars
  giochidicarte = 'giochidicarte',
  // eslint-disable-next-line no-unused-vars
  winner = 'winner',
  // eslint-disable-next-line no-unused-vars
  favTeams = 'favTeams',
}

export type CardType = keyof typeof enCardType;

export type ConditionalCardProps<T extends CardTypes> = {
  type: CardType;
  item: T;
  isCover?: boolean;
  position?: number | string;
  jackpotClass?: string;
  containerClass?: string;
  triggerRefetch?: LazyGetTriggerType;
};

export const ConditionalCard = ({
  item,
  type,
  isCover,
  position,
  jackpotClass,
  containerClass,
  triggerRefetch,
}: ConditionalCardProps<CardTypes>) => {
  switch (type) {
    case enCardType.app:
      return <CardAppIcon {...item} />;
    case enCardType.slot:
      return (
        <SlotCard
          {...item}
          isCover={isCover}
          jackpotClass={jackpotClass}
          containerClass={containerClass}
          triggerRefetch={triggerRefetch}
        />
      );
    case enCardType.chart:
      return <ChartCard {...item} position={position} />;
    case enCardType.poker:
      return <PokerCard {...item} containerClass={containerClass} />;
    case enCardType.top10:
      return (
        <WithNumberCard
          {...item}
          isCover={isCover}
          position={position}
          provider={(item as SlotCardType).produttore}
          idProvider={(item as SlotCardType).idProduttore}
        />
      );
    case enCardType.casino:
      return (
        <CasinoCard
          {...item}
          isCover={isCover}
          jackpotClass={jackpotClass}
          containerClass={containerClass}
          triggerRefetch={triggerRefetch}
        ></CasinoCard>
      );
    case enCardType.casinolive:
      return <CasinoLiveCard {...item} isCover={isCover} containerClass={containerClass} />;
    case enCardType.giochidicarte:
      return (
        <CardsGamesCard
          {...item}
          isCover={isCover}
          jackpotClass={jackpotClass}
          containerClass={containerClass}
          triggerRefetch={triggerRefetch}
        />
      );
    case enCardType.winner:
      return <WinnerCard {...item} />;
    case enCardType.favTeams:
      return <FavTeamCard {...item} triggerRefetch={triggerRefetch} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <React.Fragment />;
};

// export const ConditionalCard = renderConditional;
