import classNames from 'classnames';
import { convertInCurrencyValue } from '../../../utility/functions';
import styles from './WinnerCard.module.scss';

export type WinnerCardProps = {
  wagerAmount: number;
  amountWon: number;
  currency?: 'EUR' | string;
  position: number;
  labelTopLeft?: string;
  labelBottomLeft?: string;
};

export const WinnerCard = ({
  position,
  amountWon,
  wagerAmount,
  currency,
  labelBottomLeft,
  labelTopLeft,
}: WinnerCardProps) => {
  return (
    <article className={styles.container}>
      <span className={styles.position}>{position}</span>
      <div className={styles.card}>
        <div className={classNames(styles.topContainer)}>
          {labelTopLeft && <p className={styles.name}>{labelTopLeft}</p>}
          {wagerAmount && (
            <p className={classNames(styles.name, styles.amountRight)}>
              {convertInCurrencyValue(wagerAmount / 100, currency)}
            </p>
          )}
        </div>
        <div className={classNames(styles.textContainer)}>
          {labelBottomLeft && <span className={styles.name}>{labelBottomLeft}</span>}
          <p className={styles.fullAmount}>{convertInCurrencyValue(amountWon / 100, currency)}</p>
        </div>
      </div>
    </article>
  );
};
