import React from 'react';
// import { launchGame } from '../../actionBar/utils';
import { GamesCard, GamesCardType } from '../gamesCard';
import { CardsGamesCardDescription, CardsGamesCardDescriptionType } from './cardsGamesCardDescription';
// CasinoCard
export type CardsGamesCardProps = GamesCardType & CardsGamesCardDescriptionType;

export const CardsGamesCard: React.FC<CardsGamesCardProps> = (props) => {
  return <GamesCard {...props}>{props.gameMode && <CardsGamesCardDescription gameMode={props.gameMode} />}</GamesCard>;
};
