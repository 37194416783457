import { CardNotAvailableImage } from 'components/cards/components/cardNotAvailableImage';
import { IconsEnum } from 'components/Icons';
import { useGetLabelByKey } from 'hooks/useLingUI';
import styles from './CasinoLiveClosed.module.scss';

export type CasinoLiveClosedProps = {};

export const CasinoLiveClosed = ({}: CasinoLiveClosedProps) => {
  const tableClosed = useGetLabelByKey('table-closed');
  return (
    <div className={styles.container}>
      <CardNotAvailableImage imageId={IconsEnum.GAME_SLASH} label={tableClosed} />
    </div>
  );
};
