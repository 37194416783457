export type Dealer = {
  dealerName: string;
  dealerPictureUrl: string;
  asaCompliant: boolean;
};
/* eslint-disable no-unused-vars */
export enum TableStatus {
  Online = 'online',
  Scheduled = 'scheduled',
}

export type Table = {
  id: number;
  name: string;
  launchAlias: string[];
  imsGameType: string;
  liveGameType: string;
  tableColor: string;
  dedicated: boolean;
  status: TableStatus;
  language: string;
  languageFlagImageUrl: string;
  dynamicImageUrl: string;
  asaAlternativeImage: string;
  dealer: Dealer;
  platforms: string[];
  playerCount: number;
  min?: number;
  max?: number;
  freeSeatsCount?: number;
  seatsAvailability?: number;
  lastNumbers?: Array<number>;
  openingDateTime?: Date;
  continent: string;
  created: Date;
  updated?: any;
};

export type CasinoLiveCatalog = {
  games: Table[];
  listInEsclusiva: string[];
  listUltimiLiberi: string[];
  listRoulette: string[];
  listBlackjack: string[];
  listInItaliano: string[];
  listPoker: string[];
  listAltreLingue: string[];
};
