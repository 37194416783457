import styles from './CardWinnability.module.scss';

export type CardWinnabilityProps = {
  min: number;
  max: number;
};

export const CardWinnability = ({ min, max }: CardWinnabilityProps) => {
  const format = (value: number) => {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      compactDisplay: 'short',
      maximumFractionDigits: value < 1 ? 2 : 0,
      currencyDisplay: 'symbol',
    }).format(value);
  };

  return <p className={styles.container}>{`${format(min / 100)} - ${format(max / 100)}`}</p>;
};
