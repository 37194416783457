import Image, { ImageProps } from 'next/image';
import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { gamePlatforms, launchGame } from '../../../lib/launchGame/utils';

import { ActionBarProps } from '../../actionBar/ActionBar';
import { CardFavouriteBtn } from '../components/cardFavouriteBtn';
import { CardHoverMask } from '../components/cardHoverMask';
import { CardNotAvailableImage } from '../components/cardNotAvailableImage';
import { CardTitle } from '../components/cardTitle';
import { LaunchGameType } from 'lib/launchGame/types';
import Script from 'next/script';
import { breakpoint } from 'utility/constant';
import { callbackOnAuthenticated } from 'utility/auth/utils';
import { getNonce } from 'lib/policy';
import { getSrcImage } from '../utils';
import styles from './WithNumberCard.module.scss';
import { useCodeGameByWindowSize } from 'hooks/useCodeGameByWindowSize';
import { useGetLabelByKey } from 'hooks/useLingUI';
import { useInternalSession } from 'hooks/useInternalSession';
import { useIsAuthenticated } from 'hooks/useIsAuthenticated';
import useWindowSize from 'hooks/useWindowSize';

export type WithNumberCardProps = {
  slug: string;
  id?: string;
  image: ImageProps & {
    srcThumbnail: string;
    srcCover: string;
  };
  position?: string;
  title: string;
  isLoggedIn: boolean;
  isFavorite?: boolean;
  isCover: boolean;
  verticale: string;
} & Omit<ActionBarProps, 'hasDemo'>;

export const WithNumberCard: React.FC<WithNumberCardProps> = ({
  title,
  image,
  isCover,
  position,
  isFavorite,
  ...props
}: WithNumberCardProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [injectScript, setInjectScript] = useState<boolean>(false);
  const labelGioca = useGetLabelByKey('gioca')!;

  const { session } = useInternalSession();
  const { name, cardNumber, contractCode } = session?.user ?? {};

  const { id, slug, playInfo, isInstant, gameMode, provider, verticale, codicePiattaforma } = props ?? {};
  const { width, product, height, codeGameMobile, codeGameDesktop } = playInfo ?? {};

  const gameScript = gamePlatforms[codicePiattaforma as keyof typeof gamePlatforms];
  const gameScriptSrc = gameScript?.urlParams?.FIM_CONF;
  const [codeGame] = useCodeGameByWindowSize(codeGameDesktop, codeGameMobile);

  const { width: viewportWidth } = useWindowSize();
  const isMobile = viewportWidth! < breakpoint.md;

  // const [addFavourite, response] = usePostFavouriteMutation();

  const gameType: LaunchGameType = useMemo(
    () => ({
      codicePiattaforma,
      gameCode: codeGame,
      cardNumber: cardNumber!,
      username: name!,
      isDemo: false,
      isInstant,
      gameMode,
      provider: provider!,
      contractCode: contractCode!,
      product: product as LaunchGameType['product'],
      dimension: {
        width,
        height,
      },
      isMobile,
      idProvider: +props.idProduttore! ?? '',
    }),
    [
      codicePiattaforma,
      codeGame,
      cardNumber,
      name,
      isInstant,
      gameMode,
      provider,
      contractCode,
      product,
      width,
      height,
      isMobile,
      props.idProduttore,
    ]
  );

  const handleLaunchGame = useCallback(() => {
    setInjectScript(true);
    launchGame(gameType);
  }, [gameType]);

  const isAuthenticated = useIsAuthenticated();

  const hasImg: boolean =
    (image?.srcThumbnail !== undefined && image?.srcThumbnail !== '') ||
    (image?.srcCover !== undefined && image?.srcCover !== '');

  const srcImage = useMemo(() => getSrcImage(isCover, image), [isCover, image]);

  return (
    <React.Fragment>
      {gameScriptSrc && injectScript && <Script src={gameScriptSrc} nonce={getNonce()} />}
      <article className={styles.container}>
        {isMobile ? (
          <WithNumberCardMobileContainer
            handleLaunchGame={() => callbackOnAuthenticated(isAuthenticated, handleLaunchGame)}
          >
            {hasImg ? (
              <div className={styles.imgContainer}>
                <Image className={styles.image} src={srcImage} alt={image.alt ?? ''} title={image.title} fill />
              </div>
            ) : (
              <CardNotAvailableImage />
            )}
            <span className={styles.number}>{position}</span>
            {isAuthenticated && (
              <div className={styles.headerContainer}>
                <CardFavouriteBtn favourite={isFavorite!} verticale={props.verticale} idGame={props.id!} />
              </div>
            )}
          </WithNumberCardMobileContainer>
        ) : (
          <WithNumberCardDesktopContainer setIsHover={setIsHover}>
            {hasImg ? (
              <div className={styles.imgContainer}>
                <Image className={styles.image} src={srcImage} alt={image.alt ?? ''} title={image.title} fill />
              </div>
            ) : (
              <CardNotAvailableImage />
            )}
            <span className={styles.number}>{position}</span>
            {isAuthenticated && (
              <div className={styles.headerContainer}>
                <CardFavouriteBtn favourite={isFavorite!} verticale={props.verticale} idGame={props.id!} />
              </div>
            )}

            {gameScript && (
              <CardHoverMask
                label={labelGioca}
                handleLaunchGame={() => callbackOnAuthenticated(isAuthenticated, handleLaunchGame)}
                visible={isHover}
              />
            )}
          </WithNumberCardDesktopContainer>
        )}
        <CardTitle title={title} url={'gioco/' + props.slug} />
      </article>
    </React.Fragment>
  );
};

const WithNumberCardDesktopContainer = ({
  children,
  setIsHover,
}: {
  children: React.ReactNode;
  setIsHover: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div className={styles.cover} onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      {children}
    </div>
  );
};

const WithNumberCardMobileContainer = ({
  children,
  handleLaunchGame,
}: {
  children: React.ReactNode;
  handleLaunchGame: () => void;
}) => {
  return (
    <div className={styles.cover}>
      <button type="button" className={styles.button} onClick={handleLaunchGame}></button>
      {children}
    </div>
  );
};
