import { BackgroundImage } from 'components/backgroundImage';
import styles from './CardFallbackImage.module.scss';

export const CardFallbackImage = () => {
  return (
    <div className={styles.container}>
      <BackgroundImage imageClassName={styles.logo} />
    </div>
  );
};
