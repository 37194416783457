import { CardAppType } from 'components/cardApp';
import Image from 'next/image';
import styles from './CardAppIcon.module.scss';

export type CardAppIconProps = Partial<CardAppType>;

export const CardAppIcon = ({ title, image, className }: CardAppIconProps) => {
  return (
    <div className={`${className ? className : styles.headingContainer}`}>
      {image && <Image src={image.src} alt={image.alt ?? ''} title={image.title} className={`${styles.image}`} fill />}
      {title && (
        <div className={styles.titleContainer}>
          <span className={styles.title}>{title}</span>
        </div>
      )}
    </div>
  );
};
